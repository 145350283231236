#post-footer {
  padding-top: 2rem;
  padding-bottom: 3rem;
  text-align: center;
  color: #878787;
}

#post-footer p {
  margin: 0;
}

#post-footer a {
  color: #878787;
}

#company-logo {
  height: 75px;
}
