header {
  background-color: var(--color-light-bg);
  display: grid;
  place-items: center;
}

nav {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  height: 100px;
  width: 90%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-transform: uppercase;
}

.menu-item {
  display: block;
  align-self: center;
  padding: 1rem;
  color: black;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
}

.menu-item:hover {
  color: var(--color-brown);
}

.logo {
  flex: auto;
  align-self: center;
  display: grid;
  grid-template-columns: auto 1fr;
  text-decoration: none;
}

.logo img {
  margin: 0 17px;
  height: 50px;
  align-self: center;
}

.logo p {
  font-family: var(--post-header-font-family);
  font-size: 18px;
  color: black;
  align-self: center;
}
