#post-header {
  padding-bottom: .5rem;
  background-color: var(--color-light-bg);
}

#post-header h1 {
  font-size: 35px;
  margin: .3em 0;
}

#date-published {
  font-size: 17px;
  padding: 15px 0;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 300;
  font-style: italic;
}
