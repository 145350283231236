#list-of-posts {
  list-style-type: none;
  padding: 0;
  border-top: 1px solid lightgray;
}

#list-of-posts li {
  border-bottom: 1px solid lightgray;
  border-spacing: 0px;
  border-collapse: collapse;
  max-width: var(--page-width);
}

#list-of-posts a {
  color: black;
  text-decoration: none;
  display: block;
  padding: 10px;
}

#list-of-posts a:hover {
  background-color: whitesmoke;
}
